import React from 'react';
import Layout from '../components/Layout';

const EasyLanguage = () => {
  return (
    <Layout pageTitle="Impressum" pageUrl="impressum">
      <section
        className="max-w-screen-lg pr-6 pl-6 m-auto"
        aria-labelledby="page-heading"
      >
        <h1
          id="page-heading"
          className="text-center lg:text-5xl text-2xl font-bold uppercase lg:mb-12 mb-6"
        >
          Impressum
        </h1>
        <div className="prose lg:prose-xl max-w-full">
          <p>
            <strong>Anbieter</strong><br />
            Presse- und Informationsamt der Bundesregierung<br />
            Dorotheenstraße 84<br />
            10117 Berlin<br />
            Telefon: 030 18272-0<br />
            Fax: 030 18272-2555<br />
            <a href="mailto:internetpost@bundesregierung.de">internetpost@bundesregierung.de</a>
          </p>
          <p>
            <strong>Gesetzlicher Vertreter</strong><br />
            Chef des Presse- und Informationsamtes der Bundesregierung
          </p>
          <p>
            Das <strong>Infotelefon der Bundesregierung</strong> erreichen Sie montags bis freitags
            von 8:30 bis 17:30 Uhr unter: 030 18272-2720.
          </p>
          <p>
            <strong>Briefanschrift</strong><br />
            11044 Berlin<br />
            Online-Dienste der Bundesregierung
          </p>
          <p>
            <strong>Verantwortlich für den Inhalt</strong><br />
            Johannes Dimroth<br />
            Leiter der Abteilung Politische Information
          </p>
          <strong>Konzeption, Redaktion und Kreation</strong>
          <p>
            <a href="https://hirschen.com/" target="_blank" rel="nofollow noopener noreferrer">Zum goldenen Hirschen GmbH<span className="sr-only"> (öffnet ein neues Fenster)</span></a>, <a href="https://3pc.de/" target="_blank" rel="nofollow noopener noreferrer">3pc GmbH Neue Kommunikation<span className="sr-only"> (öffnet ein neues Fenster)</span></a>
          </p>
          <strong>Technische Umsetzung und Hosting</strong><br />
          <p>
            <a href="https://3pc.de/" target="_blank" rel="nofollow noopener noreferrer">3pc GmbH Neue Kommunikation<span className="sr-only"> (öffnet ein neues Fenster)</span></a>
          </p>

          <h2>Rechtliche Hinweise</h2>
          <p>
            Alle Angaben unseres Internetangebotes wurden sorgfältig geprüft. Wir
            bemühen uns, dieses Informationsangebot aktuell und inhaltlich richtig
            sowie vollständig anzubieten. Dennoch ist das Auftreten von Fehlern
            nicht völlig auszuschließen. Eine Garantie für die Vollständigkeit,
            Richtigkeit und letzte Aktualität kann daher nicht übernommen werden.<br />
          </p>
          <p>
            Das Presse- und Informationsamt der Bundesregierung kann dieses Informationsangebot nach eigenem Ermessen jederzeit ohne
            Ankündigung verändern und / oder dessen Betrieb einstellen. Es ist nicht verpflichtet, Inhalte dieses Informationsangebotes
            zu aktualisieren.
          </p>
          <p>
            Zugang zu diesem Informationsangebot und seine Benutzung geschehen auf
            eigene Gefahr des Benutzers. Das Presse-und Informationsamt der
            Bundesregierung ist nicht verantwortlich und übernimmt keinerlei Haftung
            für Schäden, unter anderem für direkte, indirekte, zufällige, vorab
            konkret zu bestimmende oder Folgeschäden, die angeblich durch den oder
            in Verbindung mit dem Zugang zu diesem Internetangebot und/oder seiner Benutzung aufgetreten sind.
          </p>
          <p>
            Das Internetangebot der Bundesregierung kann externe Links auf die
            Internetseiten Dritter enthalten. Auf den Inhalt dieser Seiten haben wir
            keinen Einfluss. Das Presse- und Informationsamt der Bundesregierung
            übernimmt keine Verantwortung für die Inhalte und die Verfügbarkeit von
            Internetseiten Dritter, die über externe Links dieses
            Informationsangebotes erreicht werden. Das Presse- und Informationsamt
            der Bundesregierung distanziert sich ausdrücklich von allen Inhalten,
            die möglicherweise straf- oder haftungsrechtlich relevant sind oder
            gegen die guten Sitten verstoßen.
          </p>
          <p>
            Bei der Anforderung von Inhalten aus diesem Internetangebot der
            Bundesregierung werden Zugriffsdaten gespeichert. Das sind Daten über
            die Seite, von der aus die Datei angefordert wurde, der Name der
            angeforderten Datei, Datum, Uhrzeit und Dauer der Anforderung, die
            übertragene Datenmenge sowie der Zugriffsstatus (z. B. Datei übertragen,
            Datei nicht gefunden). Diese gespeicherten Daten werden ausschließlich
            zu statistischen Zwecken ausgewertet und nicht an Dritte weitergeleitet.
          </p>
          <p>
            Sofern innerhalb des Internetangebotes der Bundesregierung die
            Möglichkeit zur Eingabe persönlicher oder geschäftlicher Daten
            (Mailadressen, Namen, Anschriften) besteht, erfolgt die Preisgabe
            dieser Daten seitens des Nutzers auf ausdrücklich freiwilliger Basis.
          </p>

          <h2>Hinweise und Regeln für die Kommentierung in den sozialen Medien</h2>
          <p>
            In unseren Social-Media-Kanälen sind Kommentare zu den redaktionellen
            Inhalten ausdrücklich erbeten. Nicht erwünscht sind dagegen themenfremde
            Beiträge und Kommentare von unangemessenem Umfang oder Wiederholungen
            unter verschiedenen Posts, ebenso Werbung und Kampagnen aller Art,
            insbesondere mehrfache inhaltsgleiche Kommentare eines Absenders oder
            verschiedener Absender. Beleidigungen, Verleumdungen und üble Nachrede
            sind hier wie überall strafbar. Schmähungen von Personen oder
            Organisationen, vulgäre Beiträge sowie persönliche Provokationen sind
            nach unserer Netiquette untersagt. Wir behalten uns im Interesse aller
            Nutzer vor, derartige Beiträge zu entfernen, insbesondere
            gewaltverherrlichende, diskriminierende, rassistische,
            fremdenfeindliche, sexistische, menschenverachtende oder
            verfassungsfeindliche Äußerungen. Als Kommunikationssprachen wünschen
            wir uns Deutsch oder Englisch.
          </p>
          <p>Bitte beachten Sie darüber hinaus die jeweils gültige Netiquette für die einzelnen Kanäle.</p>
        </div>
      </section>
    </Layout>
  );
};

export default EasyLanguage;
